
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    isMany: {
      type: Boolean,
      default: true,
    },
    urlGoTo: {
      type: String,
      default: "",
    },
    sameDomain: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goToHandler() {
      if (this.sameDomain) this.$router.push(`${this.urlGoTo}`);
      else window.open(this.urlGoTo, "_self");
    },
  },
};
