
export default {
  props: ["username", "avatar", "level", "position", "gender", "id", "slug"],
  methods: {
    rejectMember() {
      this.$emit("feedback", "rejected", this.id);
    },
    acceptMember() {
      this.$emit("feedback", "active", this.id, this.username);
    },
  },
};
