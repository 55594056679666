
export default {
  props: ["id"],
  data() {
    return {
      listItem: [
        {
          name: "Delete Post",
          icon: "bx-trash",
          action: "report",
          style: "text-red-500",
        },
        {
          name: "Report Post",
          icon: "bx-comment-x",
          action: "report",
          style: "text-red-500",
          // role: ['id']
        },
      ],
    };
  },
  methods: {
    handleDropdown(action) {
      switch (action) {
        case "report":
          this.reportDiscussion();
          break;

        default:
          break;
      }
    },
    async reportDiscussion() {
      if (!this.$store.state.auth.user.isLogin) {
        this.$goTo("login");
      } else {
        const formData = new FormData();
        formData.append("id", this.id);
        await this.$axios
          .post(`${process.env.BASE_API}communities/questions/report`, formData)
          .then((res) => {
            if (res.status) {
              this.$notify.success({
                message: "Berhasil melaporkan pertanyaan",
                offset: 100,
                duration: 3500,
              });
            } else {
              this.$notify.error({
                title: "Gagal melaporkan pertanyaan",
                offset: 100,
                message:
                  "Terjadi kesalahan server, silahkan coba beberapa saat lagi",
              });
            }
            this.active_report = false;
          })
          .catch(() => {
            this.active_report = false;
            this.$notify.error({
              title: "Gagal melaporkan pertanyaan",
              offset: 100,
              message:
                "Terjadi kesalahan server, silahkan coba beberapa saat lagi",
            });
          });
      }
    },
    async deleteDiscussion() {
      await this.$axios
        .delete(`${process.env.BASE_API}communities/questions/${id}`)
        .then((res) => {
          if (res.status) {
            this.$notify.success({
              message: "Berhasil melaporkan pertanyaan",
              offset: 100,
              duration: 3500,
            });
          } else {
            this.$notify.error({
              title: "Gagal melaporkan pertanyaan",
              offset: 100,
              message:
                "Terjadi kesalahan server, silahkan coba beberapa saat lagi",
            });
          }
        })
        .catch(() => {
          this.$notify.error({
            title: "Gagal melaporkan pertanyaan",
            offset: 100,
            message:
              "Terjadi kesalahan server, silahkan coba beberapa saat lagi",
          });
        });
    },
  },
};
