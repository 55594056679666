
export default {
  props: ["description", "communityId", "id", "isListing"],
  props: {
    description: {
      type: String,
      default: "",
    },
    communityId: {
      type: Number,
    },
    id: {
      type: Number,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    url() {
      if (this.isListing)
        return `${process.env.BASE_COMMUNITY}mbls/detail/${this.communityId}/${this.id}`;
      else
        return `${process.env.BASE_COMMUNITY}mb/community/${this.communityId}/${this.id}`;
    },
  },
};
